<template>
  <div class="me">
    <!-- header -->
    <div class="header-box dfc por">
      <!-- TODO 背景 遮挡 -->
      <div class="header dfr">
        <!-- 头像box -->
        <div class="header-portrait">
          <div class="portrait-img w100 h100">
            <img
              v-if="userInfo.info && userInfo.info.avatar"
              :src="userInfo.info.avatar"
              alt=""
              class="w100 h100 object-cover"
            />
            <img
              v-else
              src="../assets/images/default-portrait.png"
              alt=""
              class="w100 h100 object-cover"
            />
          </div>
          <!-- type 1 主持人 0私董 -->
          <div v-if="userInfo.info && userInfo.info.type == 1">
            <div
              v-if="userInfo.info && userInfo.info.is_auth"
              class="badge-img fs0"
            >
              <img
                src="../assets/images/home-emblem.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
          <div v-else>
            <div
              v-if="userInfo.info && userInfo.info.is_major_auth"
              class="badge-img fs0"
            >
              <img
                src="../assets/images/home-emblem.png"
                alt=""
                class="w100 h100"
              />
            </div>
          </div>
        </div>
        <!-- 姓名电话box -->
        <div class="name-box dfc">
          <div v-if="userInfo.info" class="name fs40 fw600 fcfff por dfr">
            {{ userInfo.info.name }}
            <!-- 铭牌box -->
            <div class="name-plate-box dfc ">
              <name-plate
                isBig
                v-if="userInfo.info.type == 1"
                class="name-plate dfr flex-center"
                :nameplate="userInfo.hostUser.level.name"
              /><name-plate
                isBig
                v-else
                class="name-plate dfr flex-center"
                nameplate="私董"
              />
            </div>
          </div>
          <div v-if="userInfo.info" class="phone fs28 fcfff op5">
            {{ userInfo.info.phone }}
          </div>
        </div>

        <div class="withdrawal-box dfr ">
          <!-- 提现 -->
          <div
            class="withdrawal-item withdrawal dfc main-center"
            v-if="userInfo.info && userInfo.info.type == 1"
          >
            <icy-button
              border="1px solid #F2C16F"
              width="1.5rem"
              @click.native="go('withdrawal')"
              boxshadow=""
              fontSize=".28rem"
              color="#F2C16F"
              height=".64rem "
              background="transparent"
              >去提现</icy-button
            >
          </div>
          <!-- 充积分按钮 -->
          <div
            class="withdrawal-item dfc main-center"
            v-if="
              userInfo.info &&
                (userInfo.info.type == 0 || userInfo.info.type == 1)
            "
          >
            <icy-button
              border="1px solid #F2C16F"
              width="1.5rem"
              @click.native="goCharge"
              boxshadow=""
              fontSize=".28rem"
              color="#F2C16F"
              height=".64rem "
              background="transparent"
              >充积分</icy-button
            >
          </div>
        </div>
      </div>

      <!-- 经验 积分 -->
      <div v-if="userInfo.info" class="integral-box dfr cross-center">
        <div
          v-if="userInfo.info.type == 0"
          class="fw400 fcfff fs24 op7 dfr align"
        >
          私董经验
        </div>
        <div v-else class="fw400 fcfff fs24 op7 align">
          已主持
        </div>
        <div v-if="userInfo.info.type == 1" class="num-box fw500 fs44 dfr">
          {{
            parseFloat(userInfo.hostUser.base_count) +
              parseFloat(userInfo.hostUser.real_count) || 0
          }}
        </div>
        <div v-else class="num-box fw500 fs44">
          {{ parseFloat(userInfo.info.experience) || 0 }}
        </div>
        <div @click="go('point')" class="integral fw400 fcfff fs24 op7 align">
          我的积分
        </div>
        <div @click="go('point')" class="num-box fw500 fs44">
          {{ parseFloat(userInfo.info.balance) || 0 }}
        </div>
      </div>
      <div class="left-transform"></div>
      <div class="right-transform"></div>
    </div>

    <!-- 私董圈 -->
    <!-- type 1 主持人 0私董 -->
    <div
      class="meeting-card-item"
      @click="goSidongRing"
      v-if="userInfo.info && userInfo.info.type == 1"
    >
      <div class="si-dong-ring dfr cross-center main-between">
        <div class="dfr cross-center">
          <div class="left-img fs0">
            <img
              src="../assets/images/sidongRing.png"
              alt=""
              class="w100 h100"
            />
          </div>
          <div class="fs28 fc333 ml20">我的私董圈</div>
        </div>
        <div class="dfr cross-center">
          <div class="fs24 fc999 mr6">{{ sidongNum }}人</div>
          <van-icon name="arrow" color="#999" />
        </div>
      </div>
    </div>

    <!-- 0=>'我创建的' 1=>'我主持的' 2=>'我参与的'  默认0 type -->
    <!-- 我发起的会议 -->
    <div
      v-if="
        userInfo.info && (userInfo.info.type == 0 || userInfo.info.type == 1)
      "
      class="meeting-card-item"
    >
      <me-meeting
        title="我发起的会议"
        type="0"
        :num="sendNum"
        :waitNum="sendWaitNum"
      />
    </div>
    <!-- 我参与的会议 -->
    <div
      v-if="
        userInfo.info && (userInfo.info.type == 0 || userInfo.info.type == 1)
      "
      class="meeting-card-item"
    >
      <me-meeting
        title="我参与的会议"
        type="2"
        :num="joinNum"
        :waitNum="joinWaitNum"
      />
    </div>

    <!-- 我主持的会议 -->
    <div
      v-if="userInfo.info && userInfo.info.type == 1"
      class="meeting-card-item"
    >
      <me-meeting
        title="我主持的会议"
        type="1"
        :num="hostNum"
        :waitNum="hostWaitNum"
      />
    </div>

    <!-- 关于我们 -->
    <div class="wrap-card me-common meeting-card-item" v-if="userInfo.info">
      <me-list
        :dataList="aboutUsList"
        :type="userInfo.info.type + ''"
        :userinfo="userInfo"
      ></me-list>
    </div>
    <!-- 退出登录 -->
    <div @click="logout" class="btn-white-box">
      退出登录
    </div>
    <!-- 我已充完 -->
    <van-popup
      :close-on-click-overlay="false"
      v-model="payPopup"
      class="pay-popup"
    >
      <div class="content-box dfc flex-center">
        <div class="dialog-box dfc cross-center main-between">
          <div class="popup-title fs40 fc333 fb">积分充值</div>
          <div class="dfr w100 mt40">
            <icy-button
              width="6rem"
              height=".8rem"
              @click.native="payPopupClose"
              >我已充值</icy-button
            >
          </div>
        </div>
        <div class="close-box" @click="payPopupClose">
          <img class="w100" src="../assets/images/popup-close.png" alt="" />
        </div>
      </div>
    </van-popup>
    <tab-bar v-show="show" active="3"></tab-bar>
  </div>
</template>

<script>
import { isWeiXinBrowser, isApplet } from "../utils/util";
import { getParam, getParamByURL, wechatBackWithCode } from "../utils/logon";
export default {
  data() {
    return {
      // type 1 主持人  0私董
      root: 1,
      sendNum: "0",
      joinNum: "0",
      hostNum: "0",
      sendWaitNum: "0",
      joinWaitNum: "0",
      hostWaitNum: "0",
      show: true,
      userInfo: {},
      aboutUsList: [
        {
          title: "个人信息",
          type: "info",
          path: "perfect",
          tel: "",
          number: 0
        },
        {
          title: "联系我们",
          type: "tel",
          path: null,
          tel: "",
          number: 0
        },
        {
          title: "平台版本",
          type: "version",
          path: null,
          version: "v1.00",
          number: 0
        }
      ],
      // 私董圈人数
      sidongNum: 0,
      payPopup: false
    };
  },
  async created() {
    this.$store.commit("changeGoMe");
    this.$title("个人中心");
    await this.isLogin();
    await this.getUserInfo();
    this.initTel();
    this.initVersion();
    if (this.userInfo.info.type == 1) {
      this.getSidongNum();
    }
  },
  mounted() {},
  methods: {
    payPopupClose() {
      this.payPopup = false;
      this.getUserInfo();
    },
    go(path) {
      this.$go(path);
    },
    goCharge() {
      // charge
      if (isApplet()) {
        this.payPopup = true;
        let jwt = this.$localStore.get("JWT");
        const chargeData = {
          pointRMBRate: 1 / this.$config.pointRMBRate,
          jwt
        };
        //定义path 与小程序的支付页面的路径相对应
        const path =
          "/pages/charge/charge?chargeData=" +
          encodeURIComponent(JSON.stringify(chargeData));
        //通过JSSDK的api跳转到指定的小程序页面
        window.wx.miniProgram.navigateTo({ url: path });
      } else {
        this.$go("charge");
      }
    },
    async initTel() {
      const resp = await this.$API.get("tel", "", false).then(resp => resp);
      if (resp.code == 1000) {
        this.aboutUsList[1].tel = resp.data.phone;
      }
    },
    async initVersion() {
      const resp = await this.$API.get("version", "", false).then(resp => resp);
      if (resp.code == 1000) {
        this.aboutUsList[2].version = resp.data.version;
      }
    },
    isLogin() {
      let isLogin = this.$localStore.get("isLogin");
      if (!isLogin) {
        this.$go("login");
        return;
      }
    },
    logout() {
      this.$localStore.set("isLogin", false);
      this.$go("login");
    },
    async getUserInfo() {
      const resp = await this.$API.get("userInfo", false).then(resp => resp);
      if (resp.code == 1000) {
        // sendNum: "0",
        // joinNum: "0",
        // hostNum: "0",
        this.userInfo = resp.data;
        this.sendNum = resp.data.info.meeting_count + "";
        this.joinNum = resp.data.info.meeting_user_count + "";
        this.hostNum = resp.data.info.host_meeting_count + "";
        // meet 发起的 host 主持的 meeting_user 参加的
        this.sendWaitNum = resp.data.info.wait_meeting_count + "";
        this.joinWaitNum = resp.data.info.wait_meeting_user_count + "";
        this.hostWaitNum = resp.data.info.wait_host_meeting_count + "";
        const needUpdateWxcode = this.$localStore.get("needUpdateWxcode");

        // 不是小程序环境才会执行
        if (needUpdateWxcode && !isApplet()) {
          let code = "";
          if (isWeiXinBrowser()) {
            code = getParam("code");
            if (!code) {
              wechatBackWithCode();
            }
          } else {
            code = getParamByURL(location.href, "code");
          }
          if (code) {
            const resp = await this.$API
              .post("bindWechat", { code })
              .then(resp => resp);

            if (resp.code == 1000) {
              this.$localStore.set("needUpdateWxcode", false);
            }
          }
        }
        if (
          !this.userInfo.info.name ||
          !this.userInfo.info.avatar ||
          !this.userInfo.info.company ||
          !this.userInfo.info.industry_id ||
          !this.userInfo.info.scale_id ||
          !this.userInfo.info.job
        ) {
          this.$goReplace("perfect", { type: this.userInfo.info.type });
        }
      }
    },
    // 获取私董圈人数
    async getSidongNum() {
      const resp = await this.$API.get("circleNum").then(resp => resp);
      if (resp.code == 1000) {
        this.sidongNum = resp.data.count;
      }
    },
    // 私董圈
    goSidongRing() {
      this.$go("sidongRing");
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.pay-popup {
  background: transparent;
  .dialog-box {
    width: 6.3rem;
    height: 2.8rem;
    background: #ffffff;
    border-radius: 0.24rem;
    padding: 0.4rem 0.6rem;
    .popup-content {
      &.p-f {
        margin: 0.68rem 0 0.36rem;
      }
      &.p-t {
        margin: 0.2rem 0 0.8rem;
      }
    }
  }
  .close-box {
    width: 0.72rem;
    height: 0.74rem;
    margin-top: 0.8rem;
  }
}
// 私董圈
.si-dong-ring {
  width: 6.94rem;
  height: 1.2rem;
  background: #fff;
  box-shadow: 0 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
  padding: 0.4rem 0.36rem;
  margin: 0 auto 0.2rem;
  .left-img {
    width: 0.4rem;
    height: 0.4rem;
  }
}

.meeting-card-item {
  transform: translateY(-0.6rem);
  z-index: 1;
  position: relative;
}
.btn-white-box {
  transform: translateY(-0.6rem);
  margin-bottom: 0;
}
.me {
  width: 7.5rem;
  box-sizing: border-box;
}
.header-box {
  width: 7.5rem;
  height: 4rem;
  overflow: hidden;
  box-sizing: border-box;
  background: #181818;
  position: relative;
  z-index: 1;
  .header {
    margin-top: 0.8rem;
    .header-portrait {
      width: 1.08rem;
      height: 1.08rem;
      margin-left: 0.44rem;
      border: 0.04rem solid #f2c16f;
      border-radius: 50%;
      position: relative;
      .portrait-img {
        img {
          border-radius: 50%;
        }
      }
      .badge-img {
        width: 0.28rem;
        height: 0.28rem;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    .name-box {
      margin-left: 0.28rem;
      .name {
        margin-bottom: 0.05rem;
        min-height: 0.56rem;
      }
    }
    .name-plate-box {
      margin: 0.06rem 0 0 0.16rem;
      .name-plate {
        border: 0.01rem solid #7c5a2f;
        color: #7c5a2f;
      }
    }
    .withdrawal-box {
      height: 1.08rem;
      position: absolute;
      right: 0.28rem;

      .withdrawal-item {
        transform: scale(0.8);
        transform-origin: right center;
        &.withdrawal {
          transform: translateX(0.1rem) scale(0.8);
        }
      }
    }
  }
  .integral-box {
    margin-left: 1.8rem;
    margin-top: 0.38rem;
    .num-box {
      margin-left: 0.16rem;
      color: #f2c16f;
    }
    .integral {
      margin-left: 0.6rem;
    }
    .align {
      margin-top: 0.09rem;
    }
  }
}
.left-transform {
  width: 0.3rem;
  height: 0.15rem;
  background: #fdfdfd;
  transform: rotate(9deg);
  position: absolute;
  left: -0.02rem;
  top: 3.91rem;
}
.right-transform {
  width: 0.3rem;
  height: 0.15rem;
  background: #fdfdfd;
  transform: rotate(169deg);
  position: absolute;
  right: -0.02rem;
  top: 3.91rem;
}
.initiate-box {
  margin-top: -0.6rem !important;
  overflow: hidden;
  z-index: 2;
  position: relative;
}
.me-common {
  width: 6.94rem;
  margin: 0.28rem auto;
  background: #ffffff;
  box-shadow: 0rem 0.04rem 0.4rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 0.12rem;
}
</style>
